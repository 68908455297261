import { Edit, SimpleForm, ArrayInput, SimpleFormIterator, TextInput, required, TextField } from "react-admin"
import { capitalize } from 'lodash';
import { Typography, Box, Grid } from '@mui/material';
import { BottomToolbar } from "../../components/BottomToolbar";
import './Interims.css';

export const EditInterim = () => {
    const translatableFields = ['title', 'description', 'question']
    return (
        <Edit>
            <SimpleForm toolbar={<BottomToolbar form={'interims'} saveWithConfirm={false} confirmText={''}/>}>
            {translatableFields.map((field, index) => (
                <Box key={index}>
                <Grid container spacing={2}>
                    <Grid item className="gridStyle">
                        <Box className='translationFieldsStyle'>
                            <Typography variant='h6' className="nonEditableFieldsText">{`${capitalize(field)} EN`}</Typography>
                            <TextField source={`${field}.en`} label='' multiline />
                        </Box>
                    </Grid>
                    <Grid item className="gridStyle">
                        <Box className='translationFieldsStyle'>
                            <Typography variant='h6' className="nonEditableFieldsText">{`${capitalize(field)} DE`}</Typography>
                            <TextField source={`${field}.de`} label='' multiline />
                        </Box>
                    </Grid>
                </Grid>
                </Box>
            ))}
            <Typography variant='h6' className="nonEditableFieldsText">Animation</Typography>
            <TextField source={'animation'} label='' multiline />
            <Typography variant="h6" className="animationLabelsTitle">{`Add animation steps text`}</Typography>
                <ArrayInput source="animSteps" label='Make sure to add text for each step of the slider'>
                    <SimpleFormIterator inline >
                        <TextInput source="de" label="German Translation" multiline resettable validate={required("Animation step text is required")} className="animStepsInput"/>
                        <TextInput source="en" label="English Translation" multiline resettable validate={required("Animation step text is required")} className="animStepsInput"/>    
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    )
}