import TurndownService from 'turndown';
import ShowDown from 'showdown';
import { camelCase } from 'lodash'

export const transformBacksideBeforeSave = (backsideOfQuestion) => {
    const turndownService = new TurndownService()
    return {
        "en": turndownService.turndown(backsideOfQuestion.en),
        "de": turndownService.turndown(backsideOfQuestion.de),
    };
}

export const transformBacksideBeforeShow = (backsideOfQuestion) => {
    const converter = new ShowDown.Converter()
    return {
        "en": converter.makeHtml(backsideOfQuestion.en),
        "de": converter.makeHtml(backsideOfQuestion.de), 
    };
}

export const removeEmptyValues = (obj) => {
    for (const key in obj) {
        if (obj[key] && typeof obj[key] === 'object') {
          obj[key] = removeEmptyValues(obj[key]);
    
          if (Object.keys(obj[key]).length === 0 && obj[key].constructor === Object) {
            delete obj[key];
          }

          if (Array.isArray(obj[key]) && obj[key].length === 0) {
            delete obj[key];
          }
        } else if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
          delete obj[key];
        }
      }
      return obj;
  }

export const createDimensionTypeForQ = (requestBody) => {
  requestBody.aspect = requestBody.aspTransl?.en ? camelCase(requestBody.aspTransl.en) : undefined
  requestBody.dimension = requestBody.dimTransl?.en ? camelCase(requestBody.dimTransl.en) : undefined
}

export const cleanSetData = (setData) => {
  const newSetData = {...setData}
  const interims = ['intro', 'outro', 'section']
  interims.forEach(interim => {
    if (!newSetData[interim]?.length) {
      delete newSetData[interim]
    }
  })
  return newSetData
}