import { Menu } from 'react-admin';
import QuizIcon from '@mui/icons-material/Quiz';
import ListAltIcon from '@mui/icons-material/ListAlt';

export const AppMenu = () => (
    <Menu>
        <Menu.Item to="/questions" primaryText="Questions" leftIcon={<QuizIcon/>} />
        <Menu.Item to="/surveySets" primaryText="Survey Sets" leftIcon={<ListAltIcon />}/>
        <Menu.Item to="/interims" primaryText="Interims" leftIcon={<QuizIcon/>} />
    </Menu>
);