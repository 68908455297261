import {
    cleanSetData,
    createDimensionTypeForQ,
    removeEmptyValues,
    transformBacksideBeforeSave,
    transformBacksideBeforeShow
} from "./transformers";

export const getOneUrl = (resource: string, params: any, apiUrl: string) => {
    switch (resource) {
        case 'questionsSetsMapping':
            return `${apiUrl}/questions/${params.id}?surveySetMap=true`;
        case 'questions':
        case 'interims':
            return `${apiUrl}/${resource}/${params.id}`;
        case 'surveySets':
            return `${apiUrl}/surveysets/${params.meta.setId}/${params.meta.scopeId}`;
        default:
            throw new Error(`Unknown resource: ${resource}`);
    }
};

export const getListUrl = (resource: string, params: any, apiUrl: string) => {
    switch (resource) {
        case 'questions':
            return params.filter.type
                ? `${apiUrl}/questions?questionsType=${params.filter.type}`
                : `${apiUrl}/questions`;
        case 'surveySets':
            return `${apiUrl}/surveysets`
        default:
            throw new Error(`Unknown resource: ${resource}`);
    }
};

export const getCreateUrl = (resource: string, apiUrl: string) => {
    switch (resource) {
        case 'questions':
            return `${apiUrl}/questions`;
        case 'surveySets':
            return `${apiUrl}/surveysets`;
        default:
            throw new Error(`Unknown resource: ${resource}`);
    }
};

const prepareQuestionBody = (data: any) => {
    const reqBody = removeEmptyValues(Object.assign({}, data));
    createDimensionTypeForQ(reqBody);
    if (reqBody.backside) reqBody.backside = transformBacksideBeforeSave(reqBody.backside);
    return reqBody;
};

const prepareQuestionUpdateBody = (params: any) => {
    const { data } = params;
    const reqBody = removeEmptyValues(Object.assign({}, {
        ...data,
        revision: data.revision + 1
    }));
    delete reqBody['id'];
    if (reqBody.backside) reqBody.backside = transformBacksideBeforeSave(reqBody.backside)
    createDimensionTypeForQ(reqBody);
    return reqBody;
};

const prepareSurveySetBody = (data: any) => {
    const { questions, ...result } = data;
    result.questions = questions.map(questionArr => questionArr.questionId);
    return cleanSetData(result);
};

const prepareSurveyUpdateBody = (params: any) => {
    const { questions, id, ...result } = params.data;

    const previousQuestions = params.previousData.questions.map(questionArr => questionArr.questionId)
    const currentQuestions = questions.map(questionArr => questionArr.questionId)

    result.questions = currentQuestions
    const data = cleanSetData(result)

    const addedQuestions = currentQuestions.filter(element => !previousQuestions.includes(element));
    const removedQuestions = previousQuestions.filter(element => !currentQuestions.includes(element));
    return { ...data, removedQuestions, addedQuestions }
}

const getInterimUpdateBody = (params) => {
    const { id, ...data } = params.data;
    return data
}

export const transformListRes = (resource: string, res: any) => {
    switch (resource) {
        case 'questions':
            return res.json.map((question: any) => ({
                ...question,
                id: question.questionId || question.interimType,
            }));

        case 'surveySets':
            return res.json.map((set: any) => ({
                ...set,
                id: `${set.setId}#${set.scopeId}`,
            }));

        default:
            throw new Error(`Unknown resource: ${resource}`);
    }
};

export const transformGetOneRes = (resource: string, data: any, params: any) => {    
    switch (resource) {
        case 'questionsSetsMapping':
            return { ...data, id: data ? data.questionId : params.id };
        case 'questions':
            return {
                ...data,
                id: data.questionId,
                ...(data.backside && { backside: transformBacksideBeforeShow(data.backside) })
            };
        case 'surveySets':
        case 'interims':
            return { ...data, id: params.id };
        default:
            throw new Error(`Unknown resource: ${resource}`);
    }
};

export const createResourceHandlers = {
    questions: prepareQuestionBody,
    surveySets: prepareSurveySetBody
}

export const updateResourceHandlers = {
    questions: prepareQuestionUpdateBody,
    surveySets: prepareSurveyUpdateBody,
    interims: getInterimUpdateBody
}