import { Datagrid, List, TextField, ExportButton } from "react-admin"
import { InterimEditButtom } from "../../components/interims/InterimEditButton"

export const InterimsList = (props) => {
    return (
      <List {...props} actions={<ExportButton/>} pagination={<></>} resource="questions" sort={{}} filter={{ type: 'interim'}} perPage={15}>
        <Datagrid bulkActionButtons={false}>
            <TextField source='id'/>
            <TextField source='mainType'/>
            <TextField source='animation'/>
            <InterimEditButtom/>
        </Datagrid>
      </List>  
    )
}