import { fetchUtils } from 'react-admin';
import authProvider from './authProvider';
import {
    getListUrl,
    getOneUrl,
    getCreateUrl,
    transformGetOneRes,
    transformListRes,
    createResourceHandlers,
    updateResourceHandlers,
} from '../helpers/dataProviderUtils';

const apiUrl = process.env.REACT_APP_BASE_URL

const httpClient = (
    url: string,
    options: any = {}) => {
    const token = authProvider.getUserDetails();
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
}


const dataProvider = {
    getList: async (resource, params) => {
        try {
            const url = getListUrl(resource, params, apiUrl);
            const options = { method: 'GET' };

            const res = await httpClient(url, options);
            const data = transformListRes(resource, res);

            return {
                data,
                total: res.json.length,
            };
        } catch (error) {
            console.error(`Failed to fetch resource list: ${resource}`, error);
            throw new Error('An error occurred while fetching the list.');
        }
    },
    getOne: async (resource, params) => {
        try {
            const url = getOneUrl(resource, params, apiUrl);
            const options = { method: 'GET' };
    
            const res = await httpClient(url, options);
            return { data: transformGetOneRes(resource, res.json, params) };
        } catch (error) {
            console.error(`Failed to fetch resource: ${resource}`, error);
            throw new Error('An error occurred while fetching data.');
        }
    },
    create: async (resource, params) => {
        try {
            const url = getCreateUrl(resource, apiUrl);
            let reqBody;
            const createHandler = createResourceHandlers[resource]
            if (createHandler) {
                reqBody = createHandler(params.data);
            } else {
                throw new Error(`Unsupported resource type: ${resource}`);
            }
    
            const options = {
                method: 'POST',
                body: JSON.stringify(reqBody),
            };
            const res = await httpClient(url, options);
    
            return {
                data: res.json,
            };
        } catch (error) {
            console.error(`Failed to create resource: ${resource}`, error);
            throw new Error('An error occurred while creating the resource.');
        }
    },
    update: async (resource, params) => {
        try {
            const url = `${apiUrl}/${resource.toLowerCase()}/${params.id}`
            let reqBody;
            const updateHandler = updateResourceHandlers[resource];

            if (updateHandler) {
                reqBody = updateHandler(params)
            } else {
                throw new Error(`Unsupported resource type: ${resource}`);
            }

            const options = {
                method: 'PUT',
                body: JSON.stringify(reqBody),
            };
            const res = await httpClient(url, options);
            return {
                data: res.json
            }
        } catch (error) {
            console.error(`Failed to update resource: ${resource}`, error);
            throw new Error('An error occurred while creating the resource.');
        }
    },
    updateMany: async (resource, params) => {
        const options: any = {}
        const { questionDetails, ...attribute } = params.data
        options.body = JSON.stringify({ questionDetails, attribute });     
        options.method = 'PATCH';
        const url = `${apiUrl}/questions`;
        const res = await httpClient(url, options);
        return Promise.resolve({
            data: res.json
        })
    },
    getQuestionsByDimension: async (resource, params) => {
        const options: any = {}
        options.method = 'GET';
        const dimension = params.filter.dimension
        const url = `${apiUrl}/questions?dimension=${dimension}`;
 
        const res = await httpClient(url, options);
        return Promise.resolve({ data: res.json })
    },
    delete: async (resource, params) => {
        // not in use currently
        return Promise.resolve()
    },
    deleteMany: async (resource, params) => {
        if (resource === 'questions') {
            const options: any = {}
            options.method = 'DELETE';
            const ids = params.ids.join(',');
            const url = `${apiUrl}/questions?ids=${ids}`;
            const res = await httpClient(url, options);
            return Promise.resolve({
                data: res.json
            })
        }
        if (resource === 'surveySets') {
            const url = `${apiUrl}/surveysets`;
            const options = {
                method: 'DELETE',
                body: JSON.stringify({setIds : params.ids}),
            };
            const res = await httpClient(url, options);
            return Promise.resolve({
                data: res.json
            })
        }
    },
};

export default dataProvider